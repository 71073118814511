var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"container"},[_vm._m(0),_vm._m(1),_vm._m(2),_vm._m(3),_vm._m(4),_c('div',{staticClass:"info-box"},[_c('div',{staticClass:"box1"},[_c('div',{staticClass:"text1"},[_vm._v("堆场装箱")]),_c('div',{staticClass:"text2"},[_vm._v(" 主要从事海运、陆运、空运、多式联运、仓储、保险报关等相关国际物流及自营和代理各类商品及技术的进出口国际贸易业务。 ")]),_c('div',{staticClass:"box2"},_vm._l((_vm.iconList),function(item,index){return _c('div',{key:index,staticClass:"icon-box"},[_c('img',{attrs:{"src":item.icon,"alt":""}}),_c('div',{staticClass:"text3"},[_vm._v(_vm._s(item.text3))]),_c('div',{staticClass:"text4",domProps:{"innerHTML":_vm._s(item.text4)}})])}),0),_c('div',{staticClass:"text1"},[_vm._v("综合查询")]),_c('div',{staticClass:"text2"},[_vm._v(" 客户可以实时了解订单状态，通过网站自助查询也可以微信推送 ")]),_c('div',{staticClass:"box3"},_vm._l((_vm.infoImgList),function(item,index){return _c('img',{key:index,attrs:{"src":item,"alt":""}})}),0)])]),_vm._m(5)])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"banner"},[_c('div',{staticClass:"text1"},[_vm._v("精准航线运价服务")]),_c('div',{staticClass:"text2"},[_c('div',[_vm._v("精准仓位保障")]),_c('div',[_vm._v("真实货柜价格")]),_c('div',[_vm._v("100%保您满意")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"choose-box"},[_c('div',{staticClass:"choose-img"}),_c('div',{staticClass:"choose-text"},[_c('div',{staticClass:"text1"},[_vm._v("选择我们 选择品质")]),_c('div',{staticClass:"text2"},[_c('img',{attrs:{"src":require("../assets/introduction/check-circle.png"),"alt":""}}),_vm._v("提供买方信保 ")]),_c('div',{staticClass:"text2"},[_c('img',{attrs:{"src":require("../assets/introduction/check-circle.png"),"alt":""}}),_vm._v("专业货运机构 ")]),_c('div',{staticClass:"text2"},[_c('img',{attrs:{"src":require("../assets/introduction/check-circle.png"),"alt":""}}),_vm._v("全国港口代理 ")]),_c('div',{staticClass:"text2"},[_c('img',{attrs:{"src":require("../assets/introduction/check-circle.png"),"alt":""}}),_vm._v("无船承运经营资格 ")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"banner2"},[_c('div',{staticClass:"online-box"},[_c('div',{staticClass:"online-text"},[_vm._v("在线订舱 管理订单")]),_c('div',{staticClass:"online-img"})])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"package-box"},[_c('div',{staticClass:"box1"},[_c('div',{staticClass:"package-img1"}),_c('div',{staticClass:"package-text1"},[_c('div',{staticClass:"text1"},[_vm._v("产地装箱")]),_c('div',{staticClass:"text2"},[_vm._v(" 货主可根据提供的产装司机车牌照号，随时查询定位运输车辆的位。小程序可以让客户实时了解最新动态，最大程度方便客户，节约时间。 ")]),_c('div',{staticClass:"text3"},[_c('img',{attrs:{"src":require("../assets/introduction/check-circle.png"),"alt":""}}),_vm._v("准时快捷 ")]),_c('div',{staticClass:"text3"},[_c('img',{attrs:{"src":require("../assets/introduction/check-circle.png"),"alt":""}}),_vm._v("24小时 ")]),_c('div',{staticClass:"text3"},[_c('img',{attrs:{"src":require("../assets/introduction/check-circle.png"),"alt":""}}),_vm._v("GPS动态跟踪 ")])]),_c('div',{staticClass:"package-text2"},[_c('div',{staticClass:"text1"},[_vm._v("堆场装箱")]),_c('div',{staticClass:"text2"},[_vm._v(" 客户可登陆系统查看堆场装箱进度，以及倒运抵、报数据、集港等几个关键节点的状态。另外，公司会对客户委托的监装业务提供一系列标准化的装箱照片，如同客户亲临现场。 ")]),_c('div',{staticClass:"text3"},[_c('img',{attrs:{"src":require("../assets/introduction/check-circle.png"),"alt":""}}),_vm._v("过程在线 ")]),_c('div',{staticClass:"text3"},[_c('img',{attrs:{"src":require("../assets/introduction/check-circle.png"),"alt":""}}),_vm._v("监控拍照 ")]),_c('div',{staticClass:"text3"},[_c('img',{attrs:{"src":require("../assets/introduction/check-circle.png"),"alt":""}}),_vm._v("全程视频录制 ")])]),_c('div',{staticClass:"package-img2"})])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"banner3"},[_c('div',{staticClass:"box1"},[_c('div',{staticClass:"text"},[_c('div',{staticClass:"text1"},[_vm._v("数据分析")]),_c('div',{staticClass:"text2"},[_vm._v(" 普泽是目前天津货运代理行业内少有的利用自身数据做BI分析的企业，以数据分析为基础，帮助公司在揽或、优势航线拓展、优化客户服务、客户信用体系搭建、财务资金管控等方面提供了解决方案。为公司健康运营打下了坚实的基础。 ")])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"banner4"},[_c('div',{staticClass:"text"},[_vm._v(" 我们为客户提供"),_c('br'),_vm._v("买方信用投保，国际贸易订单融资、国际物流等"),_c('br'),_vm._v("一站式高标准、高效率的服务，"),_c('br'),_vm._v("企业可以把有限的时间和精力集中于生产和销售，"),_c('br'),_vm._v("更好的服务于终端客户。"),_c('br'),_vm._v("运送服务 连接全球 承接梦想"),_c('br'),_vm._v("成就未来！ ")])])
}]

export { render, staticRenderFns }