import Vue from 'vue'
import VueRouter from 'vue-router'
import home from '../views/home.vue'
import search from '../views/search.vue'
import introduction from '../views/introduction.vue'
import join from '../views/join.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'home',
    component: home,
    meta: {
      title: '首页'
    }
  },
  {
    path: '/search',
    name: 'search',
    component: search,
    meta: {
      title: '运价查询'
    }
  },
  {
    path: '/introduction',
    name: 'introduction',
    component: introduction,
    meta: {
      title: '功能介绍'
    }
  },
  {
    path: '/about',
    name: 'about',
    component: () => import('../views/about.vue'),
    meta: {
      title: '关于我们'
    }
  },
  {
    path: '/join',
    name: 'join',
    component: join,
    meta: {
      title: '加入我们'
    }
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
