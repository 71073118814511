<template>
  <div class="container">
    <div class="banner">
      <div class="search-ipt-box">
        <div class="left">
          <span> XINGANG | 新港</span>
          <i class="el-icon-d-arrow-right"></i>
        </div>
        <div class="right">
          <el-input
            class="search-ipt"
            v-model="searchKey"
            placeholder="查询目的港运价"
            :trigger-on-focus="false"
            @input="getPortList"
          ></el-input>
        </div>
        <button class="search-btn" @click="goSearch">查 询</button>
      </div>
      <div v-if="searchKey && portList.length" class="port-box">
        <div
          v-for="(item, index) in portList"
          :key="index"
          class="port-item"
          @click="handleSelect(item)"
          v-html="item.result"
        ></div>
      </div>
      <div class="text1">安全运输 ｜ 快速到达 ｜ 降低成本</div>
      <div class="text2-box">
        <div>
          <div class="text2-top">20+</div>
          <div class="text2-bottom">超20年服务经验</div>
        </div>
        <div>
          <div class="text2-top">100+</div>
          <div class="text2-bottom">上百家城市地区</div>
        </div>
        <div>
          <div class="text2-top">1000+</div>
          <div class="text2-bottom">上千家合作伙伴</div>
        </div>
      </div>
    </div>
    <div class="serve-box">
      <div class="item-title-top">海运服务项目</div>
      <div class="item-title-bottom">Maritime transport services</div>
      <div class="serve-item-box">
        <div
          class="serve-item"
          @mouseover="mouseover(item)"
          @mouseout="mouseout(item)"
          v-for="(item, index) in serveList"
          :key="index"
        >
          <div class="serve-item-top">
            <img :src="item.img" alt="" />
            <div class="text1">{{ item.text1 }}</div>
            <div class="text2">{{ item.text2 }}</div>
          </div>

          <div class="text3">
            <span v-for="(item, index) in item.textList" :key="index">
              <span class="span-text">{{ item }}</span>
              <span class="span-line" v-if="index !== 2"> / </span>
            </span>
          </div>
        </div>
      </div>
    </div>
    <div class="banner2">
      <div class="text2-box">
        <div>
          <div class="text2-top">专业海运服务20年</div>
          <div class="text2-bottom">
            精准的舱位保证 / 真实的货柜价格 / 保证100%满意
          </div>
        </div>
      </div>
    </div>
    <div class="item-title-top">特色业务项目</div>
    <div class="item-title-bottom">Special Business Projects</div>
    <div class="special-box" style="color: 222">
      <img
        class="special-img"
        v-for="(item, index) in specialImgList"
        :key="index"
        :src="item"
      />
    </div>
    <div class="item-title-top">企业环境</div>
    <div class="item-title-bottom">Company environment</div>
    <div class="company-img"></div>
    <div class="international-box">
      <div class="item-title-top wColor" style="margin-top: 0">
        国际服务网络
      </div>
      <div class="item-title-bottom wColor">International Service Network</div>
      <div class="international-img"></div>
      <div class="international-text">
        <p class="text1">提供海运/空运清关、陆运到门、整箱/拼箱等服务</p>
        <div class="text2">
          <p>北美洲：美国、加拿大全境</p>
          <p>
            欧洲：比利时、德国、意大利、荷兰、波兰、西班牙、英国、法国、瑞典、挪威、土耳其、奥地利、捷克、爱尔兰、葡萄牙、丹麦、匈牙利、罗马尼亚、芬兰、希腊
          </p>
          <p>
            亚
            洲：印尼、印度、马来西亚、巴基斯坦、越南、泰国、阿联酋迪拜、菲律宾、沙特阿拉伯、新加坡、香港、韩国、日本、黎巴嫩
          </p>
          <p>大洋洲：新西兰、澳大利亚</p>
          <p>非 洲：全境</p>
        </div>
      </div>
    </div>
    <div class="domestic-box">
      <div>
        <div class="item-title-top">国内服务网络</div>
        <div class="item-title-bottom">Domestic Service Network</div>
        <div class="box1">
          <div class="domestic-text">
            <div class="line"></div>
            <div class="address">
              <div class="address-top1">天津</div>
              <div class="address-bottom">总部</div>
            </div>
            <div class="line"></div>
            <div class="address">
              <div class="address-top2">上海、青岛</div>
              <div class="address-bottom">分公司</div>
            </div>
            <div class="line"></div>
            <div class="address">
              <div class="address-top2">西安、银川、太原</div>
              <div class="address-bottom">办事处</div>
            </div>
            <div class="line"></div>
            <div class="address">
              <div class="address-top3">大连、宁波、深圳、广州、厦门、沈阳</div>
              <div class="address-bottom">实力合作伙伴</div>
            </div>
          </div>
          <div class="domestic-img"></div>
        </div>
      </div>
    </div>
    <div class="idea-box">
      <div class="idea-text">
        <div>经营理念：我们运送货物，我们更运送服务！</div>
        <div>服务宗旨：无论第一次还是第N次，都超越客户的期望！</div>
      </div>
    </div>
    <div class="agent-box">
      <div>
        <div class="item-title-top">一级代理船东</div>
        <div class="item-title-bottom">Top level booking agent Shipowners</div>
        <div class="agent-img">
          <div class="logo-box" v-for="(item, index) in 19" :key="index">
            <img
              :src="require(`../assets/home/logo/logo${index}.jpg`)"
              alt=""
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
export default {
  data() {
    return {
      searchKey: "",
      portList: [],
      portCode:"",
      specialImgList: [
        require("../assets/home/2-1.png"),
        require("../assets/home/2-2.png"),
        require("../assets/home/2-3.png"),
        require("../assets/home/2-4.png"),
      ],
      serveList: [
        {
          img: require("../assets/home/1-1_light.png"),
          imgLight: require("../assets/home/1-1_light.png"),
          imgDark: require("../assets/home/1-1_dark.png"),
          text1: "集装箱海运进出口",
          text2:
            "专业代理天津港及全国各主要港口的集装箱海运进出口业务，二十家船东的一级订舱代理。有运价及舱位的优势资源，航线覆盖全面；进出口团队经验丰富，提供一站式全程服务，保障各环节操作顺畅高效，使您的货物流转更省心、更便捷。",
          textList: ["齐全透明", "在线订舱", "实时运价确认"],
        },
        {
          img: require("../assets/home/1-2_light.png"),
          imgLight: require("../assets/home/1-2_light.png"),
          imgDark: require("../assets/home/1-2_dark.png"),
          text1: "产地装箱",
          text2:
            "集装箱公路运输业务是我司主营业务之一，为客户提供到门服务，目前公司拥有可控车辆40余辆。公司配备完善的电话跟踪系统，可保证客户了解车辆的动态和位置，最大程度方便客户需求，节约客户时间。",
          textList: ["准时快捷", "24小时", "GPS动态跟踪"],
        },
        {
          img: require("../assets/home/1-3_light.png"),
          imgLight: require("../assets/home/1-3_light.png"),
          imgDark: require("../assets/home/1-3_dark.png"),
          text1: "堆场装箱",
          text2:
            "与堆场信息的实时共享，提供进出口拆装箱、加固、运抵等业务。公司长期备有30余家不同船公司开顶、框架、冷藏等箱型，配备多种机力及20余人工装箱，善于制作各种卷钢加固、大型工程机械加固、汽车加固、打托缠膜以及真空包装、免熏蒸木箱。",
          textList: ["过程再现", "监装拍照", "全程视频摄录"],
        },
        {
          img: require("../assets/home/1-4_light.png"),
          imgLight: require("../assets/home/1-4_light.png"),
          imgDark: require("../assets/home/1-4_dark.png"),
          text1: "报关报检",
          text2:
            "提供进出口报关、报检、换证、验货等服务，拥有A类报关企业荣誉资质。出口从递单到放行，正常单据在1小时以内；进口从递单到放行在2个工作日，有税单据在3个工作日。",
          textList: ["专业高效", "限时通关", "在线进度查询"],
        },
      ],
    };
  },
  methods: {
    handleSelect(item) {
      this.portCode = this.searchKey = item.portCode;
      this.portList = [];
    },
    mouseover(item) {
      item.img = item.imgDark;
    },
    mouseout(item) {
      item.img = item.imgLight;
    },
    //目的港
    getPortList(value) {
      this.portCode = "";
      if (value) {
        let url = `https://www.puze.info/puze/website/port/list?pageNum=1&pageSize=99999&status=0&portCode=${value}`;
        axios.get(url).then((res) => {
          const hightText = `<span style="color:#FF7200">$&</span>`;
          this.portList = res.data.rows.map((item) => {
            const result =
              item.portCode + "/" + item.portName + "/" + item.country;
            return {
              ...item,
              result: result.replace(new RegExp(value, "gi"), hightText),
            };
          });
        });
      }
    },
    goSearch() {
      if (!this.portCode) {
        this.$message.warning("请选择目的港");
      } else {
        this.$router.push({
          name:'search',
          params: {
            portCode:this.portCode
          }
        })
        // this.$router.push("/search?portCode=" + this.portCode)
      }
    }
  },
};
</script>
<style lang="scss" scoped>
.container {
  line-height: 1;
  .item-title-top {
    height: 70px;
    color: #0d5697;
    text-align: center;
    font-size: 48px;
    margin-top: 50px;
  }
  .item-title-bottom {
    height: 35px;
    color: #0d5697;
    text-align: center;
    font-size: 24px;
    margin-bottom: 30px;
  }
  .banner {
    height: 600px;
    background: url("../assets/home/banner.jpg");
    background-size: 100% 100%;
    padding-top: 200px;
    position: relative;
    .search-ipt-box {
      position: relative;
      width: 1000px;
      height: 80px;
      background: #ffffff;
      border-radius: 10px;
      margin: auto;
      display: flex;
      z-index: 3;
      .left {
        color: #333333;
        font-size: 24px;
        flex-shrink: 0;
        line-height: 80px;
        padding: 0 30px;
        font-weight: 600;
        i {
          margin-left: 20px;
          font-weight: 600;
        }
      }
      .right {
        flex: 1;
        display: flex;
        align-items: center;
        ::v-deep {
          .el-input input {
            color: #ff7200;
            font-size: 30px;
            font-weight: 600;
          }
          .el-input__inner {
            border: none;
            &::placeholder {
              color: #ff7200 !important;
              font-size: 24px;
              font-weight: 600;
            }
          }
        }
      }
      .search-btn {
        flex-shrink: 0;
        width: 120px;
        height: 60px;
        font-weight: 600;
        background: #0d5697;
        border-radius: 10px;
        color: #ffffff;
        border: none;
        margin: 10px;
        font-size: 24px;
        line-height: 60px;
        cursor: pointer;
      }
    }
    .port-box {
      width: 1000px;
      background: #ffffff;
      box-shadow: 0px 4px 16px 0px rgba(0, 0, 0, 0.16);
      border-radius: 10px;
      position: absolute;
      top: 280px;
      left: calc(50% - 500px);
      z-index: 2;
      padding: 12px 20px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      max-height: 500px;
      overflow: auto;
      .port-item {
        line-height: 2;
        cursor: pointer;
        &:hover {
          color: #0d5697;
          text-decoration-line: underline;
          text-decoration-color: #0d5697;
        }
      }
    }
    .text1 {
      font-size: 52px;
      color: #ffffff;
      line-height: 74px;
      font-weight: 600;
      text-align: center;
      margin-top: 34px;
    }
    .text2-box {
      color: #fff;
      display: flex;
      justify-content: space-between;
      width: 1180px;
      margin: auto;
      margin-top: 40px;
      .text2-top {
        font-size: 72px;
        font-weight: 600;
      }
      .text2-bottom {
        font-size: 30px;
        line-height: 62px;
      }
    }
  }
  .serve-box {
    height: 600px;
    .serve-item-box {
      margin: auto;
      width: 1200px;
      display: flex;
      justify-content: space-between;
      .serve-item {
        width: 280px;
        box-shadow: 0px 4px 16px 0px rgba(0, 0, 0, 0.16);
        border-radius: 10px;
        color: #fff;
        &:hover {
          .serve-item-top {
            background: #0d5697;
          }
          .text1,
          .text2 {
            color: #fff;
          }
        }
        .serve-item-top {
          padding: 10px;
          height: 300px;
          box-shadow: 0px 13px 19px 0px rgba(0, 0, 0, 0.07);
          border-radius: 10px 10px 10px 10px;
          box-sizing: border-box;
        }
        img {
          width: 120px;
        }
        .text1 {
          font-size: 24px;
          line-height: 34px;
          padding: 0 10px;
          margin-bottom: 7px;
          font-weight: 600;
          color: #0d5697;
        }
        .text2 {
          font-size: 12px;
          line-height: 17px;
          font-weight: 400;
          color: #666666;
          padding: 0 10px;
        }
        .text3 {
          font-weight: 400;
          color: #666666;
          line-height: 60px;
          text-align: center;
          font-size: 14px;
          > span:hover {
            .span-text {
              color: #ff7200;
            }
          }
          .span-line {
            margin: 5px;
          }
        }
      }
    }
  }
  .banner2 {
    height: 300px;
    background: url("../assets/home/banner2.jpg");
    background-size: 100% 100%;
    .text2-box {
      color: #fff;
      display: flex;
      justify-content: flex-end;
      width: 1114px;
      margin: auto;
      .text2-top {
        font-size: 72px;
        text-align: center;
        margin-top: 58px;
        line-height: 105px;
      }
      .text2-bottom {
        font-size: 30px;
        margin-top: 16px;
        line-height: 50px;
      }
    }
  }
  .special-box {
    width: 1200px;
    margin: auto;
    display: flex;
    justify-content: space-between;
    .special-img {
      width: 280px;
      height: 426px;
      box-shadow: 0px 4px 16px 0px rgba(0, 0, 0, 0.16);
      border-radius: 24px;
    }
  }
  .company-img {
    width: 1200px;
    height: 526px;
    margin: auto;
    background: url("../assets/home/company.jpg");
    margin-bottom: 86px;
  }
  .international-box {
    height: 960px;
    background-color: #014886;
    padding-top: 50px;
    .wColor {
      color: #fff;
    }
    .international-img {
      height: 510px;
      width: 1280px;
      margin: auto;
      background: url("../assets/home/national-map-1.png");
    }
    .international-text {
      width: 1200px;
      height: 216px;
      margin: auto;
      text-align: left;
      color: #fff;
      .text1 {
        font-size: 20px;
        font-weight: 600;
      }
      .text2 {
        font-style: 18px;
        opacity: 0.8;
        // margin-top: 16px;
        // line-height: 2;
      }
    }
  }
  .domestic-box {
    height: 965px;
    .box1 {
      width: 1200px;
      height: 700px;
      margin: auto;
      display: flex;
      .domestic-text {
        margin-top: 60px;
        .line {
          width: 160px;
          height: 1px;
          background: #0d5697;
        }
        .address {
          height: 154px;
          padding-left: 1px;
          color: #0d5697;
          font-weight: 600;
          .address-top1 {
            font-size: 48px;
            padding-top: 25px;
            padding-bottom: 26px;
          }
          .address-top2 {
            font-size: 36px;
            padding-top: 36px;
            padding-bottom: 18px;
          }
          .address-top3 {
            font-size: 24px;
            padding-top: 25px;
            padding-bottom: 14px;
          }
          .address-bottom {
            font-size: 18px;
            color: #3d3d3d;
            line-height: 36px;
          }
        }
      }
      .domestic-img {
        width: 700px;
        height: 700px;
        background: url("../assets/home/china-map.png");
      }
    }
  }
  .idea-box {
    height: 300px;
    background: url("../assets/home/banner3.jpg");
    background-size: 100% 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    .idea-text {
      text-align: center;
      color: #fff;
      font-size: 30px;
      line-height: 72px;
    }
  }
  .agent-box {
    height: 628px;
    .agent-img {
      height: 400px;
      width: 1200px;
      box-shadow: 0px 4px 16px 0px rgba(0, 0, 0, 0.16);
      border-radius: 20px;
      margin: auto;
      background: #fff;
      display: flex;
      flex-wrap: wrap;
      padding: 20px;
      .logo-box {
        width: 232px;
        height: 92px;
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }
  }
}
</style>