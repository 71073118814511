<template>
  <div class="nav-box" :class="isfooter ? 'footer' : ''">
    <nav>
      <img class="logo" alt="Vue logo" src="../assets/logo.jpg" @click="$router.push('/')"/>
      <router-link to="/">首页</router-link>
      <router-link to="/search">运价查询</router-link>
      <router-link to="/introduction">功能介绍</router-link>
      <router-link to="/about">关于我们</router-link>
      <router-link to="/join" class="join">加入我们</router-link>
      <div v-if="isfooter" class="qr-code">
        <img src="../assets/wechat.jpg" alt="">
      </div>
    </nav>
    <div v-if="isfooter" class="foot-text">
      <div>Copyright © 2019-2024 <a href="https://www.puze.info/" target="_blank">puze.info</a> All Rights Reserved 普泽 版权所有</div>
      <a href="https://beian.miit.gov.cn/" target="_blank">ICP备案号：津ICP备2023004861号-1</a>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    isfooter: {
      type: Boolean,
      default: false, // 设置默认值为 false
    },
  },
  data() {
    return {};
  },
  created() {},
  methods: {
  },
};
</script>
<style lang="scss" scoped>
.nav-box {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  min-width: 1280px;
  z-index: 10;
  &.footer {
    position: relative;
    background: #0d5697;
    nav {
      height: 200px;
      a.router-link-exact-active {
        border: none;
      }
    }
    .join {
      margin-right: 0;
    } 
    .foot-text {
      width: 1200px;
      margin: auto;
      color: #ffffff;
      opacity: 0.6;
      position: absolute;
      left: calc(50% - 600px);
      bottom: 10px;
      text-align: center;
      line-height: 20px;
      a,> div {
        margin-bottom: 6px;
      }
    }
  }
  nav {
    height: 94px;
    display: flex;
    justify-content: space-between;
    width: 1200px;
    align-items: center;
    margin: auto;
    .logo {
      width: 60px;
      cursor: pointer;
    }
    a {
      font-weight: bold;
      color: #ffffff;
      font-size: 18px;
      position: relative;
      padding-bottom: 20px;
      &.router-link-exact-active {
        border-bottom: 2px solid #ffffff;
      }
    }
    .join {
      margin-right: 170px;
    }
    .qr-code {
      width: 150px;
      height: 150px;
      background: #d8d8d8;
      img {
        width: 100%;
      }
      
    }
  }
}
</style>