<template>
  <div class="container">
    <div class="banner">
      <div class="text1">精准航线运价服务</div>
      <div class="text2">
        <div>精准仓位保障</div>
        <div>真实货柜价格</div>
        <div>100%保您满意</div>
      </div>
    </div>
    <div class="choose-box">
      <div class="choose-img"></div>
      <div class="choose-text">
        <div class="text1">选择我们 选择品质</div>
        <div class="text2">
          <img
            src="../assets/introduction/check-circle.png"
            alt=""
          />提供买方信保
        </div>
        <div class="text2">
          <img
            src="../assets/introduction/check-circle.png"
            alt=""
          />专业货运机构
        </div>
        <div class="text2">
          <img
            src="../assets/introduction/check-circle.png"
            alt=""
          />全国港口代理
        </div>
        <div class="text2">
          <img
            src="../assets/introduction/check-circle.png"
            alt=""
          />无船承运经营资格
        </div>
      </div>
    </div>
    <div class="banner2">
      <div class="online-box">
        <div class="online-text">在线订舱 管理订单</div>
        <div class="online-img"></div>
      </div>
    </div>
    <div class="package-box">
      <div class="box1">
        <div class="package-img1"></div>
        <div class="package-text1">
          <div class="text1">产地装箱</div>
          <div class="text2">
            货主可根据提供的产装司机车牌照号，随时查询定位运输车辆的位。小程序可以让客户实时了解最新动态，最大程度方便客户，节约时间。
          </div>
          <div class="text3">
            <img src="../assets/introduction/check-circle.png" alt="" />准时快捷
          </div>
          <div class="text3">
            <img src="../assets/introduction/check-circle.png" alt="" />24小时
          </div>
          <div class="text3">
            <img
              src="../assets/introduction/check-circle.png"
              alt=""
            />GPS动态跟踪
          </div>
        </div>
        <div class="package-text2">
          <div class="text1">堆场装箱</div>
          <div class="text2">
            客户可登陆系统查看堆场装箱进度，以及倒运抵、报数据、集港等几个关键节点的状态。另外，公司会对客户委托的监装业务提供一系列标准化的装箱照片，如同客户亲临现场。
          </div>
          <div class="text3">
            <img src="../assets/introduction/check-circle.png" alt="" />过程在线
          </div>
          <div class="text3">
            <img src="../assets/introduction/check-circle.png" alt="" />监控拍照
          </div>
          <div class="text3">
            <img
              src="../assets/introduction/check-circle.png"
              alt=""
            />全程视频录制
          </div>
        </div>
        <div class="package-img2"></div>
      </div>
    </div>
    <div class="banner3">
      <div class="box1">
        <div class="text">
          <div class="text1">数据分析</div>
          <div class="text2">
            普泽是目前天津货运代理行业内少有的利用自身数据做BI分析的企业，以数据分析为基础，帮助公司在揽或、优势航线拓展、优化客户服务、客户信用体系搭建、财务资金管控等方面提供了解决方案。为公司健康运营打下了坚实的基础。
          </div>
        </div>
      </div>
    </div>
    <div class="info-box">
      <div class="box1">
        <div class="text1">堆场装箱</div>
        <div class="text2">
          主要从事海运、陆运、空运、多式联运、仓储、保险报关等相关国际物流及自营和代理各类商品及技术的进出口国际贸易业务。
        </div>
        <div class="box2">
          <div class="icon-box" v-for="(item, index) in iconList" :key="index">
            <img :src="item.icon" alt="" />
            <div class="text3">{{ item.text3 }}</div>
            <div class="text4" v-html="item.text4"></div>
          </div>
        </div>
        <div class="text1">综合查询</div>
        <div class="text2">
          客户可以实时了解订单状态，通过网站自助查询也可以微信推送
        </div>
        <div class="box3">
          <img
            v-for="(item, index) in infoImgList"
            :key="index"
            :src="item"
            alt=""
          />
        </div>
      </div>
    </div>
    <div class="banner4">
      <div class="text">
        我们为客户提供<br />买方信用投保，国际贸易订单融资、国际物流等<br />一站式高标准、高效率的服务，<br />企业可以把有限的时间和精力集中于生产和销售，<br />更好的服务于终端客户。<br />运送服务
        连接全球 承接梦想<br />成就未来！
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      iconList: [
        {
          icon: require("../assets/introduction/5-1.png"),
          text3: "船舶信息查询",
          text4: "提供准确及时、有效的<br/>船舶追踪系统",
        },
        {
          icon: require("../assets/introduction/5-2.png"),
          text3: "航线信息查询",
          text4: "全球航线海运免费查询，为您提供完<br/>善的海运航线服务",
        },
        {
          icon: require("../assets/introduction/5-3.png"),
          text3: "货物信息查询",
          text4: "了解货物信息、流程、<br/>运抵等详细信息",
        },
        {
          icon: require("../assets/introduction/5-4.png"),
          text3: "铁路信息查询",
          text4: "功能即将上线<br/>敬请期待",
        },
      ],
      infoImgList: [
        require("../assets/introduction/6-1.png"),
        require("../assets/introduction/6-2.png"),
        require("../assets/introduction/6-3.png"),
        require("../assets/introduction/6-4.png"),
      ],
    };
  },
};
</script>
<style lang="scss" scoped>
.container {
  font-size: 0;
  padding-bottom: 60px;

  .banner {
    height: 500px;
    background: url("../assets/introduction/banner.jpg");
    background-size: 100% 100%;
    padding-top: 174px;
    .text1 {
      font-size: 72px;
      color: #ffffff;
      font-weight: 600;
      text-align: center;
    }
    .text2 {
      color: #fff;
      display: flex;
      justify-content: space-between;
      width: 1128px;
      margin: auto;
      margin-top: 86px;
      font-size: 48px;
      font-weight: 600;
    }
  }
  .choose-box {
    height: 394px;
    width: 1200px;
    margin: auto;
    margin-top: 68px;
    margin-bottom: 38px;
    display: flex;
    justify-content: center;
    .choose-img {
      height: 394px;
      width: 550px;
      background: url("../assets/introduction/1.png");
      margin-top: 14px;
      margin-right: 100px;
      background-size: 100% 100%;
    }
    .choose-text {
      height: 394px;
      width: 550px;
      .text1 {
        color: #3374ae;
        font-size: 48px;
        line-height: 70px;
        margin-bottom: 33px;
      }
      .text2 {
        color: #3374ae;
        font-size: 36px;
        line-height: 70px;
        > img {
          width: 32px;
          vertical-align: middle;
          margin-right: 20px;
        }
      }
    }
  }
  .banner2 {
    height: 500px;
    background: url("../assets/introduction/banner2.jpg");
    background-size: 100% 100%;
    .online-box {
      height: 400px;
      width: 1200px;
      margin: auto;
      // margin-top: 56px;
      padding-top: 57px;
      display: flex;
      justify-content: center;
      .online-text {
        height: 400px;
        width: 600px;
        color: #fff;
        font-size: 48px;
        line-height: 70px;
        text-align: center;
      }
      .online-img {
        height: 400px;
        width: 600px;
        background: url("../assets/introduction/2.png");
        background-size: 100% 100%;
      }
    }
  }
  .package-box {
    padding: 58px 0 46px 0;
    .box1 {
      width: 1200px;
      margin: auto;
      display: flex;
      justify-content: center;
      flex-wrap: wrap;
      .package-img1 {
        height: 380px;
        width: 600px;
        background: url("../assets/introduction/3.png");
        background-size: 100% 100%;
      }
      .package-img2 {
        height: 380px;
        width: 600px;
        background: url("../assets/introduction/4.png");
        background-size: 100% 100%;
      }
      .package-text1 {
        width: 600px;
        padding-left: 50px;
        color: #3374ae;
        .text2 {
          padding-bottom: 44px;
        }
      }
      .package-text2 {
        width: 600px;
        padding-right: 50px;
        color: #3374ae;
        .text2 {
          padding-bottom: 11px;
        }
      }
      .text1 {
        font-size: 48px;
        line-height: 70px;
        margin-bottom: 19px;
      }
      .text2 {
        font-size: 16px;
        line-height: 28px;
      }
      .text3 {
        font-size: 36px;
        line-height: 70px;
        > img {
          width: 32px;
          vertical-align: middle;
          margin-right: 20px;
        }
      }
    }
  }
  .banner3 {
    height: 500px;
    background: url("../assets/introduction/banner3.jpg");
    background-size: 100% 100%;
    padding-top: 120px;
    .box1 {
      width: 1200px;
      margin: auto;
      .text {
        margin-left: 700px;
        color: #fff;
        .text1 {
          font-size: 48px;
          line-height: 70px;
          margin-bottom: 49px;
        }
        .text2 {
          font-size: 16px;
          line-height: 28px;
        }
      }
    }
  }
  .info-box {
    height: 1000px;
    .box1 {
      width: 1200px;
      margin: auto;
      padding-top: 30px;
      text-align: center;
      .text1 {
        color: #3374ae;
        font-size: 48px;
        line-height: 70px;
        margin-bottom: 8px;
      }
      .text2 {
        color: #3374ae;
        font-size: 16px;
        line-height: 28px;
        font-weight: 600;
        padding: 0 330px;
      }
      .box2 {
        width: 1100px;
        margin: auto;
        display: flex;
        justify-content: space-between;
        .icon-box {
          width: 226px;
          margin-top: 22px;
          margin-bottom: 70px;
          .text3 {
            color: #3374ae;
            font-size: 20px;
            line-height: 28px;
            margin-top: 12px;
            margin-bottom: 6px;
            font-weight: 600;
          }
          .text4 {
            font-size: 14px;
            line-height: 20px;
          }
        }
      }
      .box3 {
        margin-top: 34px;
        display: flex;
        justify-content: space-between;
        > img {
          box-shadow: 0px 4px 16px 0px rgba(0,0,0,0.16);
          border-radius: 24px;
        }
      }
    }
  }
  .banner4 {
    height: 500px;
    background: url("../assets/introduction/banner4.jpg");
    background-size: 100% 100%;
    padding: 40px 0;
    text-align: center;
    .text {
      color: #fff;
      font-size: 24px;
      line-height: 60px;
    }
  }
}
</style>
