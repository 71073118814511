<template>
  <div id="app">
    <navigation />
    <router-view />
    <navigation isfooter />
  </div>
</template>
<script>
import navigation from "@/components/nav.vue";
export default {
  components: {
    navigation,
  },
};
</script>

<style lang="scss">
</style>
