<template>
  <div class="container">
    <img class="banner" src="../assets/search/banner.jpg" alt="" />
    <div class="search-ipt-box">
      <div class="left">
        <span> XINGANG | 新港</span>
        <i class="el-icon-d-arrow-right"></i>
      </div>
      <div class="right">
        <el-input
          class="search-ipt"
          v-model="searchKey"
          :trigger-on-focus="false"
          placeholder="查询目的港运价"
          @input="getPortList"
        ></el-input>
      </div>
      <button class="search-btn" @click="search()">查 询</button>
    </div>
    <div v-if="searchKey && portList.length" class="port-box">
      <div
        v-for="(item, index) in portList"
        :key="index"
        class="port-item"
        @click="handleSelect(item)"
        v-html="item.result"
      ></div>
    </div>
    <div class="search-result">
      <div v-if="showTip && !waybill.length" class="noData">
        暂未查询到运单信息
      </div>
      <div v-else class="waybill-box">
        <div class="waybill-item" v-for="(item, index) in waybill" :key="index">
          <div class="title">
            <div>
              <span class="high-text">{{ item.week }}</span>
              <span> -- </span>
              <span class="type" v-if="item.transitPort != '直航'">
                {{ "中转：" + item.transitPort }}
              </span>
              <span v-else class="type">直航</span>
            </div>
            <div>
              <span>航程</span>
              <span class="high-text">{{ item.expectVoyage }}天</span>
            </div>
          </div>
          <div class="waybill-content">
            <div class="top">
              <div class="com-logo-box">
                <div class="com-logo" @click="search(item.companyCode)">
                  <img
                    :src="'https://www.puze.info/puze' + item.companyLogo"
                  />
                </div>
                <div style="margin-top: 4px">
                  <div>
                    <span style="letter-spacing: 1em">船</span>期：<span
                      class="blue-text"
                      >{{ item.expectSailDate }}</span
                    >
                  </div>
                  <div>
                    有效期：<span class="blue-text">{{ item.effectDate }}</span>
                  </div>
                </div>
              </div>
              <el-popover placement="bottom-end" width="174" trigger="click">
                <img src="../assets/wechat.jpg" alt="" width="150px" />
                <div slot="reference" class="serve-btn">
                  <img
                    src="../assets/search/serve.png"
                    alt=""
                    width="28px"
                    style="margin-right: 5px"
                  />
                  <span>咨询客服</span>
                </div>
              </el-popover>
            </div>
            <div class="center">
              <div
                class="wall-price-item"
                v-for="(item, index) in item.ftCostList"
                :key="index"
              >
                <div class="price-box">
                  <div class="price-top">
                    {{ item.boxType }}
                  </div>
                  <div class="price-bottom">
                    {{ item.status == 1 ? "不接" : item.oceanFreightCharge }}
                  </div>
                </div>
                <el-input-number
                  class="number-input"
                  v-model="item.num"
                  controls-position="top"
                  :disabled="item.status == 1"
                  :min="0"
                  :max="9999"
                ></el-input-number>
              </div>
            </div>
            <div class="bottom">
              <div>
                <span>人民币：</span>
                <span class="high-text"
                  >￥{{
                    item.ftCostList[0].num *
                      item.ftCostList[0].oceanFreightCharge +
                    item.ftCostList[1].num *
                      item.ftCostList[1].oceanFreightCharge +
                    item.ftCostList[2].num *
                      item.ftCostList[2].oceanFreightCharge
                  }}
                </span>
              </div>
              <div style="display: flex; justify-content: flex-end">
                <button
                  @click="showDetailDia(item)"
                  :class="{
                    'disable-btn':
                      item.ftCostList[0].num +
                        item.ftCostList[1].num +
                        item.ftCostList[2].num ===
                      0,
                  }"
                >
                  详情备注
                </button>
                <button
                  @click="showPriceDia(item)"
                  :class="{
                    'disable-btn':
                      item.ftCostList[0].num +
                        item.ftCostList[1].num +
                        item.ftCostList[2].num ===
                      0,
                  }"
                >
                  查看报价单
                </button>
              </div>
            </div>
          </div>
        </div>
        <div v-if="waybill.length" class="no-more-data">暂无更多内容...</div>
      </div>
    </div>
    <div class="dialog">
      <el-dialog :visible.sync="priceVisible" width="600px" :show-close="false">
        <div class="title">报价单</div>
        <div class="dia-content">
          <el-row class="row">
            <el-col :span="4" class="label-box">
              <div class="label">船公司</div>
              <span>:</span>
            </el-col>
            <el-col :span="20" class="value">
              {{ detailInfo.companyCode }}/{{ detailInfo.companyName }}
            </el-col>
          </el-row>
          <el-row class="row">
            <el-col :span="4" class="label-box">
              <div class="label">港口</div>
              <span>:</span>
            </el-col>
            <el-col :span="20" class="value">
              {{ detailInfo.departPortName }} --
              {{
                detailInfo.transitPort === "直航"
                  ? "直航"
                  : detailInfo.transitPortName
              }}
              -- {{ detailInfo.destPortName }}
            </el-col>
          </el-row>
          <el-row class="row">
            <el-col :span="4" class="label-box">
              <div class="label">箱量</div>
              <span>:</span>
            </el-col>
            <el-col :span="20" class="value">
              {{ detailInfo.boxNumber }}
            </el-col>
          </el-row>
          <div class="price-title">报价汇总</div>
          <div style="margin-bottom: 80px">
            人民币：<span style="color: #ff7200"
              >￥{{ detailInfo.totalPrice }}</span
            >
          </div>
        </div>
        <button class="dia-btn" @click="priceVisible = false">确定</button>
      </el-dialog>
      <el-dialog
        :visible.sync="detailVisible"
        width="600px"
        :show-close="false"
      >
        <div class="title">详情备注</div>
        <div class="dia-content">
          <el-row class="row">
            <el-col :span="4" class="label-box">
              <div class="label">起运港</div>
              <span>:</span>
            </el-col>
            <el-col :span="20" class="value">
              {{ detailInfo.departPort }}
            </el-col>
          </el-row>
          <el-row class="row">
            <el-col :span="4" class="label-box">
              <div class="label">目的港</div>
              <span>:</span>
            </el-col>
            <el-col :span="20" class="value">
              {{ detailInfo.destPort }}/{{ detailInfo.departPortName }}
            </el-col>
          </el-row>
          <el-row class="row">
            <el-col :span="4" class="label-box">
              <div class="label">预计航程</div>
              <span>:</span>
            </el-col>
            <el-col :span="20" class="value"
              >{{ detailInfo.expectVoyage }}天
            </el-col>
          </el-row>
          <el-row class="row">
            <el-col :span="4" class="label-box">
              <div class="label">预计开航日</div>
              <span>:</span>
            </el-col>
            <el-col :span="20" class="value">
              {{ detailInfo.expectSailDate }},{{ detailInfo.week }}
            </el-col>
          </el-row>
          <el-row class="row">
            <el-col :span="4" class="label-box">
              <div class="label">船东</div>
              <span>:</span>
            </el-col>
            <el-col :span="20" class="value">
              {{ detailInfo.companyCode }}/{{ detailInfo.companyName }}
            </el-col>
          </el-row>
          <el-row class="row">
            <el-col :span="4" class="label-box">
              <div class="label">海运费</div>
              <span>:</span>
            </el-col>
            <el-col :span="20" class="value">
              {{ detailInfo.ftcost }}
            </el-col>
          </el-row>
          <el-row class="row">
            <el-col :span="4" class="label-box">
              <div class="label">费用备注</div>
              <span>:</span>
            </el-col>
            <el-col :span="20" class="value">
              {{ detailInfo.costRemark }}
            </el-col>
          </el-row>
          <el-row class="row">
            <el-col :span="4" class="label-box">
              <div class="label">港口备注</div>
              <span>:</span>
            </el-col>
            <el-col :span="20" class="value">
              {{ detailInfo.portRemark }}
            </el-col>
          </el-row>
          <el-row class="row">
            <el-col :span="4" class="label-box">
              <div class="label">危品备注</div>
              <span>:</span>
            </el-col>
            <el-col :span="20" class="value">
              {{ detailInfo.perilRemark }}
            </el-col>
          </el-row>
          <el-row class="row">
            <el-col :span="4" class="label-box">
              <div class="label">特殊备注</div>
              <span>:</span>
            </el-col>
            <el-col :span="20" class="value">
              {{ detailInfo.specialRemark }}
            </el-col>
          </el-row>
          <el-row class="row">
            <el-col :span="4" class="label-box">
              <div class="label">危品详情</div>
              <span>:</span>
            </el-col>
            <el-col :span="20" class="value">
              {{ detailInfo.perilDetail }}
            </el-col>
          </el-row>
          <el-row class="row">
            <el-col :span="4" class="label-box">
              <div class="label">有效日期</div>
              <span>:</span>
            </el-col>
            <el-col :span="20" class="value">
              {{ detailInfo.effectDate }}
            </el-col>
          </el-row>
        </div>
        <button class="dia-btn" @click="detailVisible = false">确定</button>
      </el-dialog>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import { formatDate } from "@/utils/formatdate";
export default {
  data() {
    return {
      searchKey: "",
      portList: [],
      showTip: false,
      portCode: "",
      waybill: [],
      detailVisible: false,
      priceVisible: false, //报价单弹窗
      detailInfo: {},
    };
  },
  created() {
    if (this.$route.params.portCode) {
      this.portCode = this.searchKey = this.$route.params.portCode;
      this.search();
    }
  },
  methods: {
    handleSelect(item) {
      this.portCode = this.searchKey = item.portCode;
      this.portList = [];
    },
    //目的港
    getPortList(value) {
      this.portCode = "";
      if (value) {
        let url = `https://www.puze.info/puze/website/port/list?pageNum=1&pageSize=99999&status=0&portCode=${value}`;
        axios.get(url).then((res) => {
          const hightText = `<span style="color:#FF7200">$&</span>`;
          this.portList = res.data.rows.map((item) => {
            const result =
              item.portCode + "/" + item.portName + "/" + item.country;
            return {
              ...item,
              result: result.replace(new RegExp(value, "gi"), hightText),
            };
          });
        });
      }
    },
    search(companyCode) {
      if (!this.portCode) {
        this.$message.warning("请选择目的港");
      } else {
        const effectDate = formatDate(new Date(), "yyyy-MM-dd");
        let url = `https://www.puze.info/puze/website/waybill/miniList?pageNum=1&pageSize=99999&departPort=XINGANG&destPort=${this.portCode}&effectDate="${effectDate}"`;
        if (companyCode) {
          url += "&companyCode=" + companyCode;
        }
        axios.get(url).then((res) => {
          this.showTip = true;
          this.waybill = res.data.rows.map((item) => {
            const arr = item.ftCostList.map(
              (item) => "USD" + item.oceanFreightCharge + "/" + item.boxType
            );
            return {
              ...item,
              week: this.getWeek(item.expectSailDate),
              ftcost: arr.join(";"),
              ftCostList: item.ftCostList.map((item) => {
                return {
                  ...item,
                  num: 0,
                };
              }),
            };
          });
        });
      }
    },
    getWeek(dateString) {
      const weekdays = ["日", "一", "二", "三", "四", "五", "六"];
      const date = new Date(dateString);
      const weekday = date.getDay();
      return `周${weekdays[weekday]}`;
    },
    showDetailDia(item) {
      this.detailInfo = item;
      this.detailVisible = true;
    },
    showPriceDia(item) {
      this.detailInfo = item;
      if (item.transitPortName) {
        this.detailInfo.transitPortName =
          this.detailInfo.transitPortName.replace(/;/g, " -- ");
      }
      this.detailInfo.boxNumber = "";
      item.ftCostList.forEach((item1) => {
        if (item1.num) {
          this.detailInfo.boxNumber += item1.num + "*" + item1.boxType + "; ";
        }
      });
      this.detailInfo.totalPrice =
        item.ftCostList[0].num * item.ftCostList[0].oceanFreightCharge +
        item.ftCostList[1].num * item.ftCostList[1].oceanFreightCharge +
        item.ftCostList[2].num * item.ftCostList[2].oceanFreightCharge;
      this.priceVisible = true;
    },
  },
};
</script>
<style lang="scss" scoped>
.container {
  position: relative;
  padding-top: 200px;
  padding-bottom: 86px;
  min-height: calc(100vh - 200px);
  .banner {
    width: 100%;
    height: 500px;
    position: absolute;
    top: 0;
  }
  .search-ipt-box {
    position: relative;
    width: 1000px;
    height: 80px;
    background: #ffffff;
    border-radius: 10px;
    margin: auto;
    display: flex;
    z-index: 3;
    .left {
      color: #333333;
      font-size: 24px;
      flex-shrink: 0;
      line-height: 80px;
      padding: 0 30px;
      font-weight: 600;
      i {
        margin-left: 20px;
        font-weight: 600;
      }
    }
    .right {
      flex: 1;
      display: flex;
      align-items: center;
      ::v-deep {
        .el-input input {
          color: #ff7200;
          font-size: 30px;
          font-weight: 600;
        }
        .el-input__inner {
          border: none;
          &::placeholder {
            color: #ff7200 !important;
            font-size: 24px;
            font-weight: 600;
          }
        }
      }
    }
    .search-btn {
      flex-shrink: 0;
      width: 120px;
      height: 60px;
      font-weight: 600;
      background: #0d5697;
      border-radius: 10px;
      color: #ffffff;
      border: none;
      margin: 10px;
      font-size: 24px;
      line-height: 60px;
      cursor: pointer;
    }
  }
  .port-box {
    width: 1000px;
    background: #ffffff;
    box-shadow: 0px 4px 16px 0px rgba(0, 0, 0, 0.16);
    border-radius: 10px;
    position: absolute;
    top: 280px;
    left: calc(50% - 500px);
    z-index: 2;
    padding: 12px 20px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-height: 500px;
    overflow: auto;
    .port-item {
      line-height: 2;
      cursor: pointer;
      &:hover {
        color: #0d5697;
        text-decoration-line: underline;
        text-decoration-color: #0d5697;
      }
    }
  }
  .search-result {
    position: relative;
    z-index: 1;
    width: 1200px;
    height: 640px;
    background: #ffffff;
    background-image: url("../assets/search/map.png");
    background-size: 100% 100%;
    box-shadow: 0px 4px 16px 0px rgba(0, 0, 0, 0.16);
    border-radius: 20px;
    margin: auto;
    margin-top: 20px;
    .noData {
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 24px;
      color: #333333;
    }
    .waybill-box {
      width: 100%;
      height: 100%;
      display: flex;
      flex-wrap: wrap;
      align-content: flex-start;
      overflow: auto;
      .waybill-item {
        width: 50%;
        height: 280px;
        padding: 0 20px;
        border-bottom: 1px solid #d8d8d8;
        box-sizing: border-box;
        &:nth-child(odd) {
          border-right: 1px solid #d8d8d8;
        }
        .title {
          height: 50px;
          line-height: 50px;
          font-size: 18px;
          border-bottom: 1px dashed #d8d8d8;
          display: flex;
          justify-content: space-between;
          color: #333333;
        }
        .high-text {
          color: #ff7200;
        }
        .waybill-content {
          height: 230px;
          padding-top: 20px;
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          .top {
            display: flex;
            justify-content: space-between;
            .com-logo-box {
              display: flex;
              color: #333333;
              line-height: 20px;
              font-size: 14px;
              .com-logo {
                border: 1px solid #d8d8d8;
                width: 118px;
                height: 48px;
                text-align: center;
                margin-right: 10px;
                cursor: pointer;
                img {
                  height: 100%;
                }
              }
              .blue-text {
                color: #0d5697;
              }
            }
            .serve-btn {
              width: 120px;
              height: 50px;
              background: #f1f2f3;
              border-radius: 5px 5px 5px 5px;
              text-align: center;
              line-height: 50px;
              display: flex;
              justify-content: center;
              align-items: center;
              color: #3374ae;
              font-size: 16px;
              cursor: pointer;
            }
          }
          .center {
            display: flex;
            justify-content: space-between;
            .wall-price-item {
              width: 180px;
              height: 60px;
              border-radius: 5px 5px 5px 5px;
              border: 1px solid #0d5697;
              display: flex;
              .price-box {
                width: 50%;
                height: 100%;
                box-sizing: border-box;
                border-right: 1px solid #0d5697;
                color: #3d3d3d;
                font-size: 14px;
                .price-top {
                  height: 50%;
                  line-height: 30px;
                  text-align: center;
                }
                .price-bottom {
                  color: #fff;
                  background: #0d5697;
                  height: 50%;
                  line-height: 30px;
                  text-align: center;
                }
              }
              .number-input {
                position: relative;
                width: 90px;
                height: 59px;
                ::v-deep {
                  .el-input {
                    position: absolute;
                    left: 0;
                    top: 0;
                    width: 60px;
                    height: 58px;

                    input {
                      width: 100%;
                      height: 100%;
                      padding: 10px;
                      box-sizing: border-box;
                      border: none;
                      border-bottom-left-radius: 0;
                      border-bottom-right-radius: 0;
                    }
                  }
                  .el-input-number__decrease {
                    width: 30px;
                    height: 29px;
                    position: absolute;
                    top: 29px;
                    left: 59px;
                    box-sizing: border-box;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    border-width: 0 0 0 1px;
                    border-color: #dcdfe6;
                    border-style: solid;
                    border-radius: 0;
                    border-bottom-right-radius: 5px;
                    border-bottom: none;
                  }
                  .el-input-number__increase {
                    width: 30px;
                    height: 29px;
                    position: absolute;
                    left: 59px;
                    top: 0;
                    box-sizing: border-box;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    border-radius: 0;
                    border-top-right-radius: 5px;
                    border-bottom: 1px solid #dcdfe6;
                  }
                }
              }
            }
          }
          .bottom {
            height: 60px;
            border-top: 1px dashed #d8d8d8;
            line-height: 60px;
            display: flex;
            justify-content: space-between;
            align-items: center;
            button {
              cursor: pointer;
              width: 180px;
              height: 40px;
              line-height: 40px;
              background: #0d5697;
              border-radius: 5px;
              font-size: 18px;
              color: #ffffff;
              border: none;
              + button {
                margin-left: 20px;
              }
            }
          }
        }
      }
      .no-more-data {
        width: 100%;
        height: 80px;
        line-height: 80px;
        text-align: center;
      }
    }
  }
}
.dialog {
  .title {
    color: #3d3d3d;
    padding: 20px;
    font-size: 18px;
    text-align: center;
    font-weight: 600;
  }
  ::v-deep {
    .el-dialog {
      border-radius: 5px;
    }
    .el-dialog__header {
      display: none;
    }
    .el-dialog__body {
      padding: 0;
      .dia-content {
        padding: 0 26px;
        .row {
          font-size: 14px;
          line-height: 26px;
          .label-box {
            display: flex;
          }
          .label {
            text-align: justify;
            font-weight: 600;
            width: 76px;
            color: #3d3d3d;
            height: 20px;
            &::after {
              content: "";
              display: inline-block;
              padding-left: 100%;
              height: 0;
              // width: 100%;
            }
          }
          .value {
            color: #3374ae;
          }
        }
      }
      .price-title {
        height: 30px;
        line-height: 30px;
        background: #ebebeb;
        margin-top: 20px;
        margin-bottom: 10px;
        text-align: center;
      }
      button {
        width: 100%;
        height: 50px;
        background: #3374ae;
        border-radius: 0px 0px 4px 4px;
        color: #fff;
        font-size: 18px;
        border: none;
        cursor: pointer;
        margin-top: 20px;
      }
    }
  }
}
.disable-btn {
  pointer-events: none;
  opacity: 0.6;
  cursor: not-allowed !important;
}
</style>
