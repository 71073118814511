<template>
  <div class="container">
    <div class="banner">
      <div class="banner-text">我们的目标，是星辰大海</div>
    </div>
    <div class="content">
      <div class="title-zh">加入我们</div>
      <div class="title-en">Join us</div>
      <div>
        <div class="title-box">
          <div class="dot"></div>
          <div class="text">公司福利</div>
          <div class="dot"></div>
        </div>
        <div class="line"></div>
        <div class="box box1">
          <div v-for="(item, index) in iconList" :key="index">
            <img :src="require(`../assets/join/${index + 1}.png`)" alt="" />
            <div>{{ item }}</div>
          </div>
        </div>
      </div>
      <div>
        <div class="title-box">
          <div class="dot"></div>
          <div class="text">高薪诚聘</div>
          <div class="dot"></div>
        </div>
        <div class="line"></div>
        <div class="box box2">
          <div class="title">海运销售</div>
          <div class="text1">
            <div class="line1"></div>
            <div class="text">工作内容</div>
          </div>
          <div class="text2">
            1、按照公司要求，进行市场开发维护工作;<br />
            2、通过各种渠道拓展客户。
          </div>
          <div class="text1" style="margin-top: 30px">
            <div class="line1"></div>
            <div class="text">任职要求</div>
          </div>
          <div class="text2">
            1、大专及以上学历，专业不限，应届毕业生;<br />
            2、立志于从事货运代理方面工作，对销售工作有浓厚的兴趣，目标感强，有较高的市场开拓意愿;<br />3、擅长沟通交际、学习能力强、责任心强、服务意识强，团队意识强，能承受较大工作压力，熟练使用OFFICE办公软件;<br />4、为人正直、诚实、积极阳光，有正确的人生观和价值观，亲和力强;<br />
            5、在校成绩优异或有出色销售实习经验者优先考虑。
          </div>
        </div>
      </div>
      <div>
        <div class="title-box">
          <div class="dot"></div>
          <div class="text">公司团队</div>
          <div class="dot"></div>
        </div>
        <div class="line"></div>
        <div class="box box3">
          <img src="../assets/join/company.png" alt="" />
        </div>
      </div>
      <div>
        <div class="title-box">
          <div class="dot"></div>
          <div class="text">联系我们</div>
          <div class="dot"></div>
        </div>
        <div class="line"></div>
        <div class="box box4">
          <img src="../assets/join/map.jpg" alt="" />
          <div>
            <div>
              <img class="icon" src="../assets/join/call.png" alt="" />
              <span>联系电话：86-022-5878578</span>
            </div>
            <div>
              <img class="icon" src="../assets/join/email.png" alt="" />
              <span>投递邮箱：hr@starshipping.com.cn</span>
            </div>
            <div>
              <img class="icon" src="../assets/join/email.png" alt="" />
              <span>公司地址：天津市和平区贵州路18号君悦大厦B座4层</span>
            </div>
            <div style="margin-left: 44px">
              有意者请电联或发送您的简历至邮箱
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      iconList: [
        "六险一金",
        "带薪年假",
        "节日福利",
        "年底双薪",
        "全勤奖金",
        "全勤奖金",
      ],
    };
  },
};
</script>
<style lang="scss" scoped>
.container {
  padding-bottom: 50px;
  .banner {
    height: 500px;
    background: url("../assets/join/banner.jpg");
    background-size: 100% 100%;
    color: #ffffff;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    .banner-text {
      font-size: 72px;
      font-weight: 600;
    }
  }
  .content {
    width: 1200px;
    background: #ffffff;
    box-shadow: 0px 4px 16px 0px rgba(0, 0, 0, 0.16);
    border-radius: 20px 20px 20px 20px;
    margin: auto;
    margin-top: -100px;
    padding: 30px 0;
    text-align: center;
    color: #0d5697;
    .title-zh {
      font-size: 48px;
      line-height: 70px;
    }
    .title-en {
      font-size: 24px;
      line-height: 35px;
      margin-bottom: 20px;
    }
    .title-box {
      width: 400px;
      height: 60px;
      line-height: 60px;
      background: #0d5697;
      border-radius: 30px;
      color: #ffffff;
      letter-spacing: 20px;
      font-size: 30px;
      font-weight: 600;
      margin: auto;
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 0 20px;
      .dot {
        width: 16px;
        height: 16px;
        background: #ffffff;
        border-radius: 50%;
      }
    }
    .line {
      width: 184px;
      height: 22px;
      border-left: 6px solid #0d5697;
      border-right: 6px solid #0d5697;
      margin: auto;
    }
    .box {
      width: 1080px;
      background: #f1f5f6;
      border-radius: 20px;
      margin: auto;
      margin-bottom: 60px;
    }
    .box1 {
      height: 200px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 0 30px;
      color: #333333;
      font-size: 24px;
      > div {
        img {
          width: 60px;
          height: 60px;
          margin-bottom: 18px;
        }
      }
    }
    .box2 {
      padding: 15px 28px;
      .title {
        font-size: 30px;
        line-height: 48px;
        margin-bottom: 10px;
        text-align: center;
        font-weight: 600;
      }
      .text1 {
        display: flex;
        align-items: center;
        .line1 {
          width: 6px;
          height: 18px;
          background: #0d5697;
          margin-right: 6px;
          border-radius: 3px;
        }
        .text {
          font-size: 22px;
          color: #3d3d3d;
          line-height: 2;
        }
      }
      .text2 {
        font-size: 16px;
        color: #3d3d3d;
        text-align: left;
        line-height: 2;
        padding-left: 12px;
        margin-bottom: 8px;
      }
    }
    .box3 {
      padding: 40px;
      img {
        width: 100%;
      }
    }
    .box4 {
      padding: 40px;
      display: flex;
      align-items: center;
      margin-bottom: 25px;
      > div {
        line-height: 60px;
        text-align: left;
        margin-left: 70px;
        font-size: 18px;
        .icon {
          margin-right: 4px;
          vertical-align: middle;
        }
      }
    }
  }
}
</style>
